//  Constructor
function Screen(apiBase) {
	this.apiBase = apiBase;
	this.priceFactor = 2;
	this.totalKm = 5160;
	this.refreshUIDelay = 10000;
	this.refreshBikesDelay = 2000;
	this.refreshDataCounter = 0;

	this.maxCash = 10000;

	this.switchPagesPreformanceDelay = 10000;
	this.switchPagesUniversityDelay = 15000;

	this.kmFactorHack = 2;

	this.dataParticipants = {};
	this.dataUniversities = {};
	this.dataBikes = {};
	this.dataTotal = {};

	// Get data from API
	this.refreshData();

	// Automatic toggle
	this.interval = setInterval($.proxy(this.refreshData, this), this.refreshUIDelay);
	this.intervalBikes = setInterval($.proxy(this.refreshDataBikes, this), this.refreshBikesDelay);
	this.switchPagePreformanceInteval = setInterval($.proxy(this.switchPages, this, "#top-participants"), this.switchPagesPreformanceDelay);
	this.switchPageUniverstiyInteval = setInterval($.proxy(this.switchPages, this, "#top-university"), this.switchPagesUniversityDelay);

	setInterval(function(){
		$("#title-completed").toggleClass("reversed");
	}, 600);

	// Space key handler
	$(document).bind('keypress', $.proxy(function (e) {
		if (e.keyCode == 32) {
			this.gotoResults();
		}
	}, this));

}

Screen.prototype.refreshData = function (callback) {

	this.refreshDataCounter = 0;

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "top_univesities",
		//async: false,
		success: $.proxy(this.refreshDataUniversitiesCallback, this, callback),
		error: $.proxy(this.refreshDataCount, this),
	});

	$.ajax({
		dataType: "json",
		url: this.apiBase + "top_people",
		//async: false,
		success: $.proxy(this.refreshDataParticipantsCallback, this, callback),
		error: $.proxy(this.refreshDataCount, this),
	});

	$.ajax({
		dataType: "json",
		url: this.apiBase + "total",
		success: $.proxy(this.refreshTotalCallback, this, callback),
		error: $.proxy(this.refreshDataCount, this),
	});
}


Screen.prototype.refreshDataBikes = function (callback) {

	this.refreshDataCounter = 0;

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "bikes",
		//async: false,
		success: $.proxy(this.refreshDataBikesCallback, this, callback),
		error: $.proxy(this.refreshDataCount, this),
	});
}


Screen.prototype.refreshDataBikes = function (callback) {

	this.refreshDataCounter = 0;

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "bikes",
		//async: false,
		success: $.proxy(this.refreshDataBikesCallback, this, callback),
		error: $.proxy(this.refreshDataCount, this),
	});
}


Screen.prototype.refreshDataBikesCallback = function (callback, data) {
	this.refreshDataCount();
	this.dataBikes = data;

	// console.log(this.dataBikes);

	// $(".name, .km", "#top-university .top-item").html("");
	for (var i = 0; i < data.content.length; i++) {
		var $el = $("#active-bikes .bike-item").eq(i);

		var name = data.content[i].name == null ? "" : data.content[i].name;

		var currentKM = (parseFloat(data.content[i].km) - parseFloat(data.content[i].last_km)) * this.kmFactorHack;
		currentKM = !isNaN(currentKM) && currentKM != 0 ? currentKM.toFixed(1) : "--";

		var currentSpeed = parseFloat(data.content[i].speed);
		currentSpeed = !isNaN(currentSpeed) && currentSpeed != 0 ? currentSpeed : "--";

		$el.find(".name").html(name);
		$el.find(".km .value").html(currentKM);

		var speedContainer = $el.find(".speed .value");

		if (currentSpeed != "--") {
			var fromSpeed = isNaN(speedContainer.text()) ? 0 : parseFloat(speedContainer.text());
			speedContainer.countTo({
				from: fromSpeed,
				speed: 1000,
				refreshInterval: 50,
				decimals: 1,
				to: currentSpeed
			});
		} else {
			speedContainer.html(currentSpeed);
		}


	}

	if (callback) {
		callback();
	}
}

Screen.prototype.refreshDataUniversitiesCallback = function (callback, data) {
	this.dataUniversities = data;

	$("#top-university .top-item").addClass("empty")
	$(".name, .km", "#top-university .top-item").html("");
	for (var i = 0; i < data.content.length; i++) {
		var $el = $("#top-university .top-item").eq(i);
		$el.find(".name").html(data.content[i].name);
		$el.find(".km").html((parseFloat(data.content[i].km) * this.kmFactorHack).toFixed(1) + " km");
		$el.removeClass("empty");
	}

	$("#top-university .page-1").toggleClass("has-content", (data.content.length > 0));
	$("#top-university .page-2").toggleClass("has-content", (data.content.length > 10));
	$("#top-university .page-3").toggleClass("has-content", (data.content.length > 20));

	if (callback && this.refreshDataCounter == 3) {
		callback();
	}
}

Screen.prototype.refreshDataParticipantsCallback = function (callback, data) {
	this.dataParticipants = data;

	$("#top-participants .top-item").addClass("empty")
	$(".name, .km", "#top-participants .top-item").html("");
	for (var i = 0; i < data.content.length; i++) {
		var $el = $("#top-participants .top-item").eq(i);
		$el.find(".name").html(data.content[i].name);
		$el.find(".km").html(parseFloat((data.content[i].km)* this.kmFactorHack).toFixed(1)  + " km");
		$el.removeClass("empty");
	}

	$("#top-participants .page-1").toggleClass("has-content", (data.content.length > 0));
	$("#top-participants .page-2").toggleClass("has-content", (data.content.length > 10));
	$("#top-participants .page-3").toggleClass("has-content", (data.content.length > 20));

	if (callback && this.refreshDataCounter == 3) {
		callback();
	}
}

Screen.prototype.refreshTotalCallback = function (callback, data) {
	this.dataTotal = data;

	var km = (parseFloat(data.content.km) * this.kmFactorHack).toFixed(1);
	if (isNaN(km)) km = 0;

	var cash = Math.floor(parseFloat(km) * this.priceFactor);
	if (isNaN(cash)) cash = 0;

	var previousCash = parseInt($("#global-content .total-cash").text());
	$("#global-content .total-cash").countTo({
		from: previousCash,
		speed: 1000,
		refreshInterval: 50,
		decimals: 0,
		formatter: function (value, options) {
			return value.toFixed(options.decimals) + " $";
		},
		to: cash
	});


	$("#global-content .total-km").text(km + " km")
	$("#finale .km").html("<span>" + km + "</span>km")
	$("#finale .cash").html(cash)

	// Update path
	var iw = $("#path").width();
	var ih = $("#path").height();
	var progress = km / this.totalKm;
	var clipVal = 'rect(0px, ' + iw + 'px, ' + ih + 'px, ' + parseInt(iw * (1.0 - progress)) + 'px)';
	$("#path-line").css("clip", clipVal);

	if(cash >= this.maxCash) {
		$("#title-in-progress").fadeOut();
		$("#title-completed").fadeIn();
	}

	if (callback && this.refreshDataCounter == 3) {
		callback();
	}
}


Screen.prototype.switchPages = function (selector) {

	$(selector).each(function (i, n) {
		var pageCount = $(".page.has-content", n).length;
		var currentPageIndex = $(".page.active", n).data("index");

		if(typeof currentPageIndex == "undefined" ) {
			currentPageIndex = 0;
		}

		var newPageIndex = (currentPageIndex + 1) % pageCount;
		$(".page", n).eq(currentPageIndex).removeClass("active");
		var pageWidth = $(".page", n).first().width();

		$(".pages-wrapper", n).css("left", (-pageWidth * newPageIndex) + "px");

		setTimeout($.proxy(function (newPageIndex) {
			$(".page", n).eq(newPageIndex).addClass("active");
		}, this, newPageIndex), 400)
	});
}


Screen.prototype.gotoResults = function () {

	clearInterval(this.interval);
	//console.log("gotoResults")

	this.interval = setInterval($.proxy(this.refreshData, this), 15000);

	$(".km, .cash", "#finale").css("opacity", 0);

	$("#top-participants, #top-university, .bottom-content").fadeOut(400, $.proxy(function () {
		$("#finale").fadeIn();

		this.updateDOM();

		// Show KM
		setInterval(function () {
			$("#finale .km").animate({
				opacity: 1.0
			}, 400);
		}, 1000);

		// Show cash
		setInterval(function () {
			$("#finale .cash").animate({
				opacity: 1.0
			}, 400);
		}, 2000);

	}, this));
}

Screen.prototype.refreshDataCount = function () {
	this.refreshDataCounter++;
}

Screen.prototype.showElementsSequence = function (selector) {

	// Hide and display items in sequence
	$(selector).hide().each(function (i, n) {
		setTimeout((function (n) {
			return function () {
				$(n).fadeIn();
			}
		})(n), i * 500);
	});

}
