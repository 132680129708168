//  Constructor
function Tablet(apiBase) {
	this.apiBase = apiBase;
	this.currentContent = "home";
	this.lastUpdate = -1;
	this.lastUpdateActive = -1;
	this.currentEmail = "";
	this.numActiveParticipants = 0;

	this.kmFactorHack = 2;

	this.editPassword = "0000";

	// Get data from API
	this.refreshData();

	// Refresh data interval
	this.interval = setInterval($.proxy(this.refreshData, this, true), 400);

	// Hide unused parts
	$("#participants, #complete, #add, #password, #edit, #start").hide();

	$("#refresh-btn").click($.proxy(function (e) {
		e.preventDefault();
		window.location.href = window.location.href;
	}, this));

	//
	$(".goto-add").click($.proxy(function (e) {
		e.preventDefault();
		this.gotoAdd();
	}, this));

	$(".goto-participants").click($.proxy(function (e) {
		e.preventDefault();
		this.gotoParticipants();
	}, this));

	$(".goto-password").click($.proxy(function (e) {
		e.preventDefault();
		this.gotoPassword();
	}, this));

	$("#add-form").submit($.proxy(function (e) {
		e.preventDefault();
		this.addSubmit($("#add-form").serialize());
	}, this));

	$("#add-form input#email").on("keyup", $.proxy(function (e) {
		e.preventDefault();
		this.getParticipant($("#add-form").serialize());
	}, this));

	$("#edit-form #email").on("change", $.proxy(function (e) {
		e.preventDefault();
		this.getParticipantEdit($("#edit-form").serialize());
	}, this));

	$("#edit-participant-btn").on("click", $.proxy(function (e) {
		e.preventDefault();
		this.gotoPassword();
	}, this));

	$("#edit .delete").on("click", $.proxy(function (e) {
		e.preventDefault();
		this.deleteParticipant();
	}, this));

	$("#password-form").submit($.proxy(function (e) {
		e.preventDefault();
		this.passwordSubmit($("#password-form").serialize());
	}, this));

	$("#edit-form").submit($.proxy(function (e) {
		e.preventDefault();
		var form = $("#edit-form").clone();
		form.find("#km").val(parseFloat(form.find("#km").val()) / this.kmFactorHack);
		form.find("#email").val($("#edit-form #email").val());
		this.editSubmit(form.serialize());
	}, this));

	// Form submit handler : submit performance
	// $("#complete-form").submit($.proxy(function (e) {
	// 	e.preventDefault();
	// 	this.completeSubmit($("#complete-form").serialize());
	//
	// }, this));

	$("#start-form").submit($.proxy(function (e) {
		e.preventDefault();
		this.startSubmit($("#start-form").serialize());

	}, this));

	// Click handler : complete participant


	$("body").on('click', ".goto-complete", $.proxy(function (e) {
		this.gotoComplete();
	}, this));

	$("body").on('click', ".complete-performance", $.proxy(function (e) {
		var id = $(e.currentTarget).data("id");
		var name = $(e.currentTarget).closest(".item").find(".name").text();
		this.completeSubmit(id, name);
	}, this));


	$("body").on('click', ".goto-start", $.proxy(function (e) {
		var id = $(e.currentTarget).data("id");
		var name = $(e.currentTarget).closest(".item").find(".name").text();
		this.gotoStart(id, name);
	}, this));

	// Click handler : delete participant
	$("body").on('click', "#waiting .delete a", $.proxy(function (e) {
		var id = $(e.currentTarget).parent().data("id");
		// this.deleteParticipant(id);
		this.removeParticipant(id);
	}, this));

	// Back button
	$("#add .back, #complete .back, #edit .back, #password .back, #participants .back").click($.proxy(function (e) {
		e.preventDefault();
		this.gotoHome();
	}, this));

	$("#start .back").click($.proxy(function (e) {
		e.preventDefault();
		this.gotoParticipants();
	}, this));

	$("body").on('change', ".universities-checkboxes .choice", $.proxy(function (e) {
		//var id = $(e.currentTarget).data("id");
		//this.gotoComplete(id);
		$(".universities-checkboxes .choice").removeClass("selected");
		$(e.currentTarget).addClass("selected");
		//console.log("yeah");
	}, this));

	FastClick.attach(document.body);

}

/*
 Refresh data through API
 */

Tablet.prototype.refreshData = function (onlyWaiting) {

	$.ajax({
		dataType: "json",
		url: this.apiBase + "waiting",
		//async: false,
		success: $.proxy(this.refreshDataWaitingCallback, this)
	});

	$.ajax({
		dataType: "json",
		url: this.apiBase + "active",
		//async: false,
		success: $.proxy(this.refreshDataActiveCallback, this)
	});

	if (!onlyWaiting) {
		$.ajax({
			dataType: "json",
			url: this.apiBase + "universities",
			//async: false,
			success: $.proxy(this.refreshDataUniversitiesCallback, this)
		});
	}
}

Tablet.prototype.refreshDataWaitingCallback = function (data) {

	//console.log("refreshDataWaitingCallback", this.lastUpdate, data.updated_date);

	// Check if a DOM refresh is needed
	if (this.lastUpdate != data.updated_date || $("#waiting .items").children().length == 0) {
		this.lastUpdate = data.updated_date;

		this.clearWaiting();

		for (var i = 0; i < data.content.length; i++) {
			var item = data.content[i];
			//console.log(data.content[i]);

			// Add item
			var $el = $('<div class="item ' + (i % 2 == 0 ? "odd" : "even") + '"><div class="delete" data-id="' + item.id + '"><a>X</a></div><div class="group-left"><div class="name">' + item.name + '</div> / <div class="university">' + item.university_name + '</div></div><div class="group-right"><div class="goto-start" data-id="' + item.id + '">Activate a performance<br/>Activer une performance</div></div></div>');
			$("#waiting .items").append($el);
		}

		if (data.content.length == 0) {
			$("#waiting .empty").show();
		} else {
			if (this.numActiveParticipants >= 4) {
				$("#waiting .items .item .goto-start").parent().addClass("disabled");
			} else {
				$("#waiting .items .item .goto-start").parent().removeClass("disabled");
			}
			$("#waiting .empty").hide();
		}
	}

}

Tablet.prototype.refreshDataActiveCallback = function (data) {

	//console.log("refreshDataWaitingCallback", this.lastUpdate, data.updated_date);

	// Check if a DOM refresh is needed
	if (this.lastUpdateActive != data.updated_date) {
		this.lastUpdateActive = data.updated_date;

		this.clearActive();

		for (var i = 0; i < data.content.length; i++) {
			var item = data.content[i];
			// console.log(data.content[i]);

			// Add item
			var $el = $('<div class="item ' + (i % 2 == 0 ? "odd" : "even") + '"><div class="group-left"><div class="bike">Bike / Vélo ' + item.bike_id + '</div> - <div class="name">' + item.name + '</div></div><div class="group-right"><div class="complete-performance" data-id="' + item.id + '">Complete performance<br/>Compléter la performance</div></div></div>');
			$("#active .items").append($el);
		}

		this.numActiveParticipants = data.content.length;

		if (this.numActiveParticipants == 0) {
			$("#active .empty").show();
		} else {
			$("#active .empty").hide();
		}
	}

}

Tablet.prototype.refreshDataUniversitiesCallback = function (data) {

	// Clear items
	$(".universities-checkboxes").html("");

	for (var i = 0; i < data.content.length; i++) {
		var item = data.content[i];
		//console.log(data.content[i]);

		// Add item
		// var $el = $('<div class="choice"><label for="choice-' + item.id + '"><img src="images/' + item.img + '"/>' + item.name + '</label><input type="radio" name="university_id" id="choice-' + item.id + '" value="' + item.id + '"></div>');
		var $el = $('<div class="choice"><input type="radio" name="university_id" id="add-choice-' + item.id + '" value="' + item.id + '"><label for="add-choice-' + item.id + '"><span>' + item.name + '</span></label></div>');
		$(".universities-checkboxes.add").append($el);
	}

	for (var i = 0; i < data.content.length; i++) {
		var item = data.content[i];
		//console.log(data.content[i]);

		// Add item
		// var $el = $('<div class="choice"><label for="choice-' + item.id + '"><img src="images/' + item.img + '"/>' + item.name + '</label><input type="radio" name="university_id" id="choice-' + item.id + '" value="' + item.id + '"></div>');
		var $el = $('<div class="choice"><input type="radio" name="university_id" id="edit-choice-' + item.id + '" value="' + item.id + '"><label for="edit-choice-' + item.id + '"><span>' + item.name + '</span></label></div>');
		$(".universities-checkboxes.edit").append($el);
	}
}


Tablet.prototype.clearWaiting = function () {
	// Clear items
	$("#waiting .items").html("");
};

Tablet.prototype.clearActive = function () {
	// Clear items
	$("#active .items").html("");
};

/*
 Action handlers
 */

Tablet.prototype.getParticipant = function (data) {

	if ($('#add-form input#email').val() == "") {
		$('#add-form input#name').val("").prop("readonly", true);
		$("#add-form input[type='radio']").prop('checked', false).attr('disabled', true);
		$("#add-form #add-participant-btn").attr('disabled', true);
		return;
	}

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "get",
		type: "POST",
		data: data,

		//async: false,
		success: $.proxy(function (data) {
			if (data.content.length > 0) {
				$('#add-form input#name').val(data.content[0].name).prop("readonly", true);
				$("#add-form input[type='radio'][value=" + data.content[0].university_id + "]").prop('checked', true);
				$('#add-form input[type="radio"]:not(:checked)').attr('disabled', true);

				$("#add-form #add-participant-btn").attr('disabled', false);
			} else {
				$('#add-form input#name').val("").prop("readonly", false);
				$("#add-form input[type='radio']").prop('checked', false).attr('disabled', false);

				$("#add-form #add-participant-btn").attr('disabled', false);
			}
		}, this)
	});
}


Tablet.prototype.getParticipantEdit = function (data) {

	if ($('#edit-form #email').val() == "") {
		$("#edit-form input[type='submit']").attr('disabled', true);
		$('#edit-form input#name').val("").prop("readonly", true);
		$('#edit-form input#km').val("").prop("readonly", true);
		$("#edit-form input[type='radio']").prop('checked', false).attr('disabled', true);
		return;
	}

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "get",
		type: "POST",
		data: data,

		//async: false,
		success: $.proxy(function (data) {
			if (data.content.length > 0) {

				// console.log(data);
				$('#edit-form input#name').val(data.content[0].name).prop("readonly", false);
				$("#edit-form input[type='radio'][value=" + data.content[0].university_id + "]").prop('checked', true);
				$('#edit-form input[type="radio"]').attr('disabled', false);
				$('#edit-form input#km').val(data.content[0].km * this.kmFactorHack).prop("readonly", false);

				$("#edit-form input#original_email").val($("#edit-form #email").val());

				$("#edit-form input[type='submit']").attr('disabled', false);
			} else {
				$("#edit-form input[type='submit']").attr('disabled', true);
				$('#edit-form input#name').val("").prop("readonly", true);
				$('#edit-form input#km').val("").prop("readonly", true);
				$("#edit-form input[type='radio']").prop('checked', false).attr('disabled', true);
				$("#edit-form input#original_email").val("");
			}
		}, this)
	});
}


Tablet.prototype.getBikes = function (data) {

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "bikes",
		type: "POST",
		data: data,
		success: $.proxy(function (data) {

			var $bikesContainer = $("#bikes-checkboxes");
			$bikesContainer.html("");

			for (var i = 1; i <= data.content.length; i++) {
				if (data.content[i - 1].active == 0) {
					$bikesContainer.append('<input type="radio" name="bike_id" id="bike-' + i + '" value="' + i + '"><label for="bike-' + i + '"><span>' + i + '</span></label>')
				}
			}
		}, this)
	});
}

Tablet.prototype.addSubmit = function (data) {
	// Disable input
	$('#add #add-form input[type="submit"]').prop("disabled");
	$('input').blur();

	if ($('#add-form input#email').val() == "" || $('#add-form input#name').val() == "" || $("#add-form input[type='radio']:checked").val() == undefined) {
		return;
	}

	this.currentEmail = "";

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "add",
		type: "POST",
		data: data,

		//async: false,
		success: $.proxy(this.gotoParticipants, this)
	});

}

Tablet.prototype.passwordSubmit = function (data) {

	// Disable input
	$('#password #password-form input[type="submit"]').prop("disabled");
	$('input').blur();

	// Check if everything is filled
	if ($('#password-form input#pass').val() == "") {
		return;
	} else if ($('#password-form input#pass').val() != this.editPassword) {
		return;
	}

	this.gotoEdit();
}

Tablet.prototype.editSubmit = function (data) {

	// Disable input
	$('#edit #edit-form input[type="submit"]').prop("disabled");
	$('input').blur();

	// Check if everything is filled
	if ($('#edit-form input#original_email').val() == "") {
		return;
	}

	this.currentEmail = $("#edit-form #email").val();

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "edit",
		type: "POST",
		data: data,

		//async: false,
		success: $.proxy(this.gotoHome, this)
	});
}


Tablet.prototype.deleteParticipant = function () {

	// Check if everything is filled
	if ($('#edit-form input#original_email').val() == "") {
		return;
	}

	// ask to make sure we want to delete
	var go = confirm("Are you sure you want to delete the participant?\n\nÊtes-vous certain de vouloir supprimer le participant?");

	if (go) {
		// Load default settings
		$.ajax({
			dataType: "json",
			url: this.apiBase + "delete",
			type: "POST",
			//data: "id=" + id,
			data: $('#edit-form').serialize(),

			//async: false,
			success: $.proxy(this.gotoHome, this)
		});
	}

}


Tablet.prototype.removeParticipant = function (id) {

	// Hide item directly
	$("#waiting .delete[data-id='" + id + "']").parent().hide().remove();

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "remove",
		type: "POST",
		//data: "id=" + id,
		data: {id: id},

		//async: false,
		// success: $.proxy(this.gotoParticipants, this)
	});
}


Tablet.prototype.completeSubmit = function (id, name) {

	var go = confirm("Are you sure you want to complete " + name + "'s performance?\n\nÊtes-vous certain de vouloir completer la performance de " + name + "?");

	if (go) {

		$.ajax({
			dataType: "json",
			url: this.apiBase + "complete",
			type: "POST",
			data: {id: id},
			//async: false,
			success: $.proxy(this.gotoHome, this)
		});
	}

}

Tablet.prototype.startSubmit = function (data) {

	if ($("#start-form input[type='radio']:checked").val() == undefined) {
		return;
	}

	// Disable input
	$('#start #start-form input[type="submit"]').prop("disabled");
	$('input').blur();

	// Clear waiting list
	this.clearWaiting();
	this.clearActive();

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "start",
		type: "POST",
		data: data,
		//async: false,
		success: $.proxy(this.gotoParticipants, this)
	});

}


/*
 Change steps
 */
Tablet.prototype.gotoAdd = function () {

	$("#add #email").val("");
	$("#add #name").val("").prop("readonly", true);
	$('#add #add-form input[type="submit"]').attr('disabled', true);
	$('input').blur();

	$("#add-form input[type='radio']").prop('checked', false).attr('disabled', true);

	$(".part.shown").removeClass("shown").fadeOut(250, $.proxy(function () {
		$("#add").hide().fadeIn(250).addClass("shown");
		//this.showElementsSequence("#top-university .top-item");
	}, this));

	this.currentContent = "add";
}

Tablet.prototype.gotoPassword = function () {
	// this.currentEmail = $("#add #email").val();

	$("#password #pass").val("");
	$('#password #password-form input[type="submit"]').prop("disabled");
	$('input').blur();

	$(".part.shown").removeClass("shown").fadeOut(250, $.proxy(function () {
		$("#password").hide().fadeIn(250, function () {
			$("#password #pass").focus();
		}).addClass("shown");
		//this.showElementsSequence("#top-university .top-item");
	}, this));

	this.currentContent = "password";
}

Tablet.prototype.gotoEdit = function () {

	$("#edit-form input[type='submit']").attr('disabled', true);
	$('#edit-form input#name').val("").prop("readonly", true);
	$('#edit-form input#km').val("").prop("readonly", true);
	$("#edit-form input[type='radio']").prop('checked', false).attr('disabled', true);


	$("#edit-form #original_email").val("");
	$("#edit-form #email").val("");

	$.ajax({
		dataType: "json",
		url: this.apiBase + "emails",
		type: "GET",

		//async: false,
		success: $.proxy(function (data) {
			// console.log(data);

			var opts = [];

			opts.push($("<option />", {value: "", text: ""}));

			for (var i = 0; i < data.content.length; i++) {
				opts.push($("<option />", {value: data.content[i].email, text: data.content[i].email}));
			}

			$("#edit-form #email").empty().append(opts);
		}, this)
	});

	$('input').blur();

	$(".part.shown").removeClass("shown").fadeOut(250, $.proxy(function () {
		$("#edit").hide().fadeIn(250).addClass("shown");

		// var query = {
		// 	email: this.currentEmail
		// }

		// Load default settings
		// $.ajax({
		// 	dataType: "json",
		// 	url: this.apiBase + "get",
		// 	type: "POST",
		// 	data: query,
		//
		// 	//async: false,
		// 	success: $.proxy(function (data) {
		// 		console.log(data);
		// 		$("#edit-form #name").val(data.content[0].name);
		// 		$("#edit-form input[type='radio'][value=" + data.content[0].university_id + "]").prop('checked', true);
		// 		$("#edit-form #km").val(parseFloat(data.content[0].km).toFixed(2));
		// 	}, this)
		// });
		//this.showElementsSequence("#top-university .top-item");
	}, this));

	this.currentContent = "edit";
}

Tablet.prototype.gotoComplete = function () {

	$('input').blur();

	this.refreshData();

	$(".part.shown").removeClass("shown").fadeOut(250, $.proxy(function () {
		$("#home").hide();
		$("#participants").hide();
		$("#start").hide();
		$("#edit").hide();
		$("#password").hide();
		$("#complete").hide().fadeIn(250).addClass("shown");
		//this.showElementsSequence("#top-university .top-item");
	}, this));

	this.currentContent = "complete";

	//console.log("gotoComplete", id);
	// $("#complete .participant-name h4").text(name);
	// $("#complete #complete-form #id").val(id);
	// // $("#complete #complete-form #km").val("");
	// $('#complete #complete-form input[type="submit"]').prop("disabled");
	// $('input').blur();
	//
	// $(".part.shown").removeClass("shown").fadeOut(250, $.proxy(function () {
	// 	$("#add").hide();
	// 	$("#start").hide();
	// 	$("#complete").hide().fadeIn(250).addClass("shown");
	// 	//this.showElementsSequence("#top-university .top-item");
	// }, this));
	//
	// this.currentContent = "complete";
}

Tablet.prototype.gotoStart = function (id, name) {

	//console.log("gotoComplete", id);
	$("#start .participant-name h4").text(name);
	$("#start #start-form #id").val(id);
	// $("#start #start-form input[type='radio']").prop('checked', false);
	$('#start #start-form input[type="submit"]').prop("disabled");
	$('input').blur();

	this.getBikes();

	$(".part.shown").removeClass("shown").fadeOut(250, $.proxy(function () {
		$("#add").hide();
		$("#start").hide().fadeIn(250).addClass("shown");
		//this.showElementsSequence("#top-university .top-item");
	}, this));

	this.currentContent = "start";
}


Tablet.prototype.gotoParticipants = function () {

	$('input').blur();

	this.refreshData();

	this.currentEmail = "";

	$(".part.shown").removeClass("shown").fadeOut(250, $.proxy(function () {
		$("#home").hide();
		$("#complete").hide();
		$("#start").hide();
		$("#edit").hide();
		$("#password").hide();
		$("#participants").hide().fadeIn(250).addClass("shown");
		//this.showElementsSequence("#top-university .top-item");
	}, this));

	this.currentContent = "participants";
}

Tablet.prototype.gotoHome = function () {

	$('input').blur();

	this.refreshData();

	this.currentEmail = "";

	$(".part.shown").removeClass("shown").fadeOut(250, $.proxy(function () {
		$("#participants").hide();
		$("#start").hide();
		$("#edit").hide();
		$("#password").hide();
		$("#home").hide().fadeIn(250).addClass("shown");
		//this.showElementsSequence("#top-university .top-item");
	}, this));

	this.currentContent = "home";
}